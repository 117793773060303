.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  font-size: 2.2rem;
  margin-bottom: 40px;
  margin-top: 40px;
}
