.todoFormContainer {
  margin-bottom: 30px;
}

.todoFormContainer form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.todoFormContainer input {
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  width: 45%;
  font-size: 1.3rem;
  height: 30px;
}
